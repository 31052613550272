.report-table-header {
  background-color: rgba( #245278, .7 );
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0, 0,0, 0.3);
}

.report-table-cell {
  align-items: center;
  // background-color: #245278;
  backdrop-filter: blur(24px);
  border-bottom: 1px solid rgba(white, 0.1);
  height: 48px;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  color: $light-gray3;

  &.header {
    font-size: 11px;
    color: white;
    font-weight: normal;
    div {
      span {
        font-weight: normal;
      }
    }

    input.bp3-input {
      color: white !important;
    }
    
    input.bp3-input::placeholder {
      color: white !important;
      font-weight: normal;
    }

    input.bp3-input::-webkit-input-placeholder {
      color: white !important;
      font-weight: normal;
    }

    svg {
      fill: white;
    }
  }
}

.report-table-container {
  background-color: rgba( darken(#245278, 10%), .5 );
}
