
.dashboard-widget-wrapper {
  flex: 1;
  height: 110px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(24px);

  &.active {
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  &.flat {
    backdrop-filter: none;
  }

  .dashboard-widget-number {
    display: flex;
    border-radius: 7px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(255, 255, 255, 0.1);

    h1 {
      margin: 0;
      text-align: center;
      padding: 6px;
      font-size: 16px;
      font-weight: normal;
      // text-shadow: 0 1px 1px black;
      color: white;
    }

    .dashboard-widget-number-content {
      font-size: 32px;
      font-weight: normal;
      text-align: center;
      color: white;
    }

    &.flat {
      border: none;
    }
  }
}

.dashboard-widget {
  margin: 24px;
  display: block;
  height: calc(100vh - 240px);
  
  h1 {
    margin: 0;
    height: 36px;
    font-size: 20px;
    text-align: center;
  }

  .dashboard-widget-content {
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
