@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700&display=swap');
@import "~@blueprintjs/core/lib/scss/variables.scss";
@import "styles/custom-tabs.scss";
@import "styles/dashboard.scss";
@import "styles/herobox.scss";
@import "styles/navbar.scss";
@import "styles/store.scss";
@import "styles/login.scss";
@import "styles/report.table.scss";
@import "styles/progress.chart.scss";
@import "styles/patterns.datatable.scss";
@import "styles/patterns.sidebar.scss";
@import "styles/patterns.toolbar.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  font-family: 'Assistant', sans-serif;
}

body .fc {
  margin-top: 36px !important;

  th {
    background-color: white;
    font-size: 14px;
    text-transform: uppercase;
  }

  .fc-axis {
    background-color: white;
    font-size: 14px;
  }
}

body.bp3-dark {
  background-color: black;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.fc-day-header.fc-widget-header {
  color: $gray2;
}

.fc-nonbusiness.fc-bgevent {
  background-color: $light-gray3;
}

.fc-axis.fc-time {
  color: $gray2;
}

.fc-title, .fc-time {
  font-weight: bold;
}

.fc-event-container .fc-time {
  background: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 14px;
}

.drawer-content {
  background-color: $light-gray4;
}

.form-header {
  margin: 12px 0px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  color: $gray2;
}

.bp3-label {
  // text-transform: uppercase;
  font-weight: normal;
}

.patterns-flex-row {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.patterns-flex-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.bp3-input {
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;
  border-radius: none;
  border-bottom: 1px solid $gray5;
  font-weight: bold;
}

.bp3-button-text {
  font-weight: bold;
}

.event-drawer {
  .bp3-label {
    min-width: 30px;
  }
}

.button-margin {
  margin-right: 12px;
}

.row-even, .row-odd {
  width: 100%;
  padding: 12px 24px 12px 24px;
  border-bottom: 1px solid $light-gray2;

  .bp3-form-group {
    margin-bottom: 0;
  }
}

.horizontal-spacer {
  line-height: 30px;
  margin: 0 12px;
}

.flat .bp3-input {
  border: none;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  &:hover {
    background-color: $light-gray2;
  }
}

.flat textarea.textarea.bp3-input {
  border-bottom: none;
}

.bp3-control.bp3-checkbox {
  margin-bottom: 0;
  font-weight: bold;
}

.bp3-tag {
  font-weight: bold;
}

.fc-event-container .fc-time {
  background: transparent;
}

.fc-event {
  border-left: 8px solid rgba(0, 0, 0, 0.3);
  padding-left: 4px;
}

.content {
  background-color: $light-gray3;
}

.content-full {
  max-height: calc(100vh - 60px);
  background-color: $dark-gray3;
  // background-image: url(assets/bg3.png);
  background-size: cover;
  overflow-y: auto;
  // padding-bottom: 64px;
}

.content-full-gradient {
  background-image: none;
  background: rgb(9,26,50);
  background: linear-gradient(180deg, rgba(9,26,50,1) 0%, rgba(44,66,97,1) 100%);
}

.bp3-dark {



  .drawer-content {
    background-color: $dark-gray3;
  }

  .fc {
    th {
      background-color: $dark-gray4;
      border-color: $dark-gray2;
    }

    td {
      background-color: $dark-gray4;
      border-color: $dark-gray2;
    }

    .fc-divider {
      background-color: $dark-gray4;
      border-color: $dark-gray4;
    }
  }

  .event-tooltip {
    background-color: $dark-gray4;
  }

  .content-full {
    // background-color: $dark-gray4;
  }

  .bp3-input {
    box-shadow: none;
    border: none;
    border-radius: 0;
  }

  .bp3-menu-item.bp3-intent-primary:hover,
  .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item,
  .bp3-menu-item.bp3-intent-primary.bp3-active {
    background-color: $turquoise1;
  }
}

h1 {
  font-size: 28px;
  font-weight: normal;
  // margin-top: 0;
  padding-bottom: 6px;
}

h2 {
  font-weight: normal;
  color: white;
}

.btn-small {
  font-size: 14px;
}

.btn-nonbold {
  .bp3-button-text {
    font-size: 14px;
  }
  font-weight: normal !important;
}

.bp3-dialog {
  .bp3-label {
    width: 160px;
    color: $gray1;
    font-weight: normal;
  }

  .bp3-form-group.bp3-inline {
    .bp3-form-content {
      flex: 1;
    }
  }
}

.tooltip {
  z-index: 100;
}

.event-tooltip {
  background: white;
  padding: 6px 12px;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
}

.display-flex {
  display: flex;
}

.bp3-menu-item.bp3-intent-primary:hover,
.bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item,
.bp3-menu-item.bp3-intent-primary.bp3-active {
  background-color: $turquoise3;
}

.full-height-tabbed {
  height: calc(100vh - 82px);
  overflow: hidden;
}

.full-height-tabbed-peak {
  height: 840px;
  overflow: hidden;
}

.full-height-double-tabbed {
  height: calc(100vh - 84px);
  overflow: hidden;
}

.double-height-tabbed {
  height: calc(200vh - 76px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.double-half-height-tabbed {
  height: calc(178vh - 76px);
  overflow-y: auto;
  overflow-x: hidden;

  .patterns-data-table {
    height: calc(75vh - 76px) !important;
  }
}

.w-72 {
  width: 72px
}

.bp3-form-group.bp3-inline label.bp3-label {
  text-align: right;
  padding-right: 6px;
}

.table-toolbar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  span.bold {
    font-weight: normal !important;
  }
}


// .patterns-sidebar-collapsed {
//   .patterns-sidebar {
//     width: 48px;
//     height: 100%;
//     flex: unset;

//     .bp3-menu {
//       min-width: 48px;

//       li:first-of-type {
//         padding-top: 36px;
//       }
//     }

//     .bp3-menu-header {
//       display: none;
//     }

//     .bp3-menu-item {
//       margin-top: 2px;
//       margin-bottom: 2px;
//       margin-right: 0;
//       margin-left: 0;
//       height: 36px;
//       padding-top: 8px;

//       div {
//         display: none;
//       }

//       .bp3-menu-item-label {
//         display: none;
//       }
//     }
//   }
// }

.contract-detail {
  .patterns-form .bp3-form-group.bp3-inline label.bp3-label {
    width: 164px;
  }

  .custom-tabs {
    .bp3-tab-list {
      .bp3-tab {
        font-weight: bold !important;
        text-transform: uppercase !important;
      }
    }
  }
}

.patterns-panel {
  .bottom-toolbar {
    border-top: none;
    border-left: none;
    border-right: none;
    justify-content: flex-end;
  }
}

.form-align-left {
  .bp3-form-group.bp3-inline label.bp3-label {
    text-align: left;
    width: 130px;
  }
}

.bp3-dialog-header .bp3-heading {
  font-size: 18px;
}

.patterns-form .bp3-form-group.bp3-inline.long-label label.bp3-label {
  width: 100%;
  flex: unset;
  text-align: center;
  margin-left: 24px;
  font-weight: bold;
  color: $gray3;
}

.patterns-form .bp3-form-group .bp3-control {
  margin-top: 4px;
  margin-bottom: 8px;
}
.patterns-form textarea {
  height: 74px;
}


@keyframes skeleton-glower {
  from {
    background: $light-gray3;
    border-color: $light-gray3;
  }

  to {
    background: $light-gray4;
    border-color: $light-gray4;
  }
}

@keyframes skeleton-glower-dark {
  from {
    background: $dark-gray3;
    border-color: $dark-gray3;
  }

  to {
    background: $dark-gray4;
    border-color: $dark-gray4;
  }
}

.asset-dialog {
  width: 1024px;

  .patterns-form {
    &.wider {
      .bp3-form-group.bp3-inline {
        label.bp3-label {
          width: 188px;
        }
        &.long-label label.bp3-label {
          width: 100%;
        }
      }

    }
  }

  &.preview {
    width: 1280px;
  }
}

.attachments-container {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: none;
  .attachment-row {
    width: 100%;
    flex: unset;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    border-top: 1px solid $dark-gray2;
    padding-top: 6px;
    padding-bottom: 6px;
    min-height: 48px;
    background-color: $dark-gray3;

    &:hover {
      background-color: $dark-gray2;
    }

    .attachment-icon {
      width: 42px;
      text-align: center;
    }
    .attachment-title {
      flex: 1;
      font-weight: bold;
      color: $gray3;

      span {
        color: $light-gray3;
      }
    }
    .attachment-actions {
      width: 42px;
      text-align: center;
    }
  }
}

.purchase-invoice-form {
  flex: unset;
  height: 242px;
  border-bottom: 1px solid $dark-gray3;
  margin-bottom: 12px;
}

div.bp3-non-ideal-state {
  div {
    font-weight: bold;
    color: white !important;
  }

  h4.bp3-heading {
    color: white !important;
  }
}

.chart-container {
  flex: 1;
  // background-color: rgba(white, 0.7);
  border: 1px solid rgba(white, 0.1);
  background-color: rgba( #245278, .5 );
  border-radius: 7px;
  backdrop-filter: blur(24px);

  .chart-inner-title {
    border-top-left-radius: 7px;;
    border-top-right-radius: 7px;;
    background-color: rgba(darken(#245278, 5%), 0.7);
    color: white;
    text-align: left;
    padding: 18px 18px 18px 24px;
    text-transform: uppercase;
    font-weight: normal;
  }

  .chart-inner-container {
    margin: 12px;
  }
}

.invoice-attachments-title {
  padding: 12px;
  background-color: $dark-gray4;
  border-top: 1px solid $gray1;
  border-bottom: 1px solid $dark-gray1;
}

.bp3-menu.contract-actions-popup-menu {
  background-color: $dark-gray3;
}

.purchase-invoice-picker {
  .bp3-popover-wrapper {
    width: 100%;
    .bp3-popover-target {
      width: 100%;
    }
  }
}

.table-cell-image {
  max-height: 42px;
  justify-self: center;
  align-self: center;
  margin-top: 4px;
  margin-bottom: 4px;
}

.form-image-preview {
  max-width: 100%;
}

.popover-fix {
  .bp3-popover-wrapper {
    width: 100%;

    .bp3-popover-target {
      width: 100%;

      button {
        padding-right: 12px
      }

      .bp3-button-text {
        width: 100%;
      }
    }
  }
}


.red { color: $red3; }
.green { color: $green5; }
.orange { color: rgb(217, 130, 43) }

.darker {
  backdrop-filter: blur(24px);
  background-color: rgba(36, 82, 120, 0.7);

  .bp3-button.bp3-outlined.bp3-intent-primary {
    border-color: white;
    color: white;
  }

  .bp3-icon {
    color: white;
    stroke: white;
  }
}

.darker-text { color: $light-gray3; }

.bp3-dark {
  .red { color: $red5; }
  .green { color: $green5; }
  .darker { background-color: $dark-gray3; }
  .darker-text { color: $light-gray1; }
}

.scroll {
  overflow-y: auto;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $light-gray2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  // background: #166687;
  background-color: #D21144
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}

.bp3-dark {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $dark-gray1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $green3;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: black;
  }

  scrollbar-color: $green3;
  scrollbar-width: 5px;
  scrollbar-track-color: black;;
}

.h-420 {
  height: 420px;
  min-height: 420px;
  max-height: 420px;
}

.key-value-view {
  line-height: 28px;

  &.header {
    margin-block-start: 15px;
    margin-block-end: 1.33em;
  }

  .value {
    font-weight: bold;
  }
}

.w-100p {
  width: 100%;

  .bp3-form-content {
    width: calc(100% - 96px);
  }
}

.w-100 {
  width: 100px;
}

.w-130 {
  width: 130px;
}

.w-150 {
  width: 150px;
}

.w-180 {
  width: 180px;
}

.pdf-preview {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);

  .react-pdf__Document {
    max-height: 80%;
    height: 80%;
    overflow-y: scroll;
  }
}

.assets-table {
  height: calc(100vh - 100px);
}

.full-height-double-tabbed.patterns-datatable-autoheight .assets-table  .patterns-data-table {
  height: calc(100vh - 120px) !important;
}

.patterns-round-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid $gray3;
}


.table-header {
  font-weight: bold;
  padding-top: 3px;
  padding-bottom: 6px;
}


.patterns-form-suffix {
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bp3-dark {
  .paterns-form-suffix {
    background-color: $dark-gray5;
  }
}

.no-scroll {
  overflow-y: hidden !important;
}

.header {
  color: white;
  text-transform: uppercase;
}

.fake-field {
  line-height: 28px;
  font-weight: 500;
  color: white;
}

.tco-report-item {
  border-right: 1px solid $dark-gray1;
}

.clearfix {
  clear: both;
}

.quarter-select-container {
  width: 100%;
  height: 42px;
  background-color: $dark-gray3;
  text-align: center;
}

.report-item-50 {
  width: 50%;
  float: left;
}

.clearfix {
  clear: both;
}

h4.chart {
  text-align: left;
  margin-left: 24px;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  margin-top: 0;
}

.hoverable {
  &:hover {
    background-color: rgba( darken(#245278, 10%), .5 );
  }
}

.clickable {
  cursor: pointer
}

.reports-container {
  // height: calc(100%);
  overflow-y: auto;
  overflow-x: hidden;
  .bp3-button.bp3-outlined.bp3-intent-primary {
    border-color: white;
    color: white;
  }

  .bp3-icon {
    color: white;
    stroke: white;
  }

  .bp3-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  .bp3-input {
    color: white;
    border: 1px solid white impo !important;
    box-shadow: none;
    border-radius: 0;
  }
}

.m-t-15 {
  margin-top: 15px;
}

.m-l-18 {
  margin-left: 18px;
}

.m-l-30 {
  margin-left: 30px;
}

.p-l-4 {
  padding-left: 4px;
}

.w-200 {
  width: 200px;
}

.chart-pbar {
  height: 28px;

  .bp3-progress-bar {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    .bp3-progress-meter {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  &.reversed {
    .bp3-progress-bar {
      display: flex;
      justify-content: flex-end;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      .bp3-progress-meter {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

.bp3-slider {
  color: white;
}

.bp3-slider-track {
  background-color: rgba(white, 0.1);
}
.bp3-slider-handle {
  background: $gray5;

  &:hover {
    background: $gray5;
  }

  .bp3-slider-label {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: white;
    font-weight: bold;
  }
}

.purchase-invoice {
  margin-left: 36px;
  margin-top: 36px;
  float: left;
  padding: 12px;
  backdrop-filter: blur(24px);
  background-color: rgba(36, 82, 120, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.12);
  color: white;
  border-radius: 8px;
  .icon {
    width: 104px;
    height: 128px;
    margin: 6px auto;
    background-image: url(./assets/pdf-iicon.png);
    background-size: contain;
  }

  .filename {
    padding: 6px;
    text-align: center;
    font-weight: bold;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    padding: 6px;
  }

  .bp3-button {
    .bp3-icon {
      color: white;
      stroke: white;
    }

    &:hover {
      color: white;
    }
    color: white;
  }
}


.image-loader {
  border-radius: 8px;
  width: 192px;
  height: 192px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .hidden {
    display: none;
  }
}

.shop-product {
  border-radius: 8px;
  margin-left: 24px;
  margin-bottom: 36px;
  padding: 12px;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 290px;
  max-width: 238px;
  background-color: rgba( #245278, .3 );
  border: 1px solid rgba(white, 0.12);
  backdrop-filter: blur(24px);

  .filename {
    height: 64px;
    font-weight: bold;
    color: white;
    text-align: center;
  }

  .price {
    font-weight: bold;
    color: white;
    text-align: center;
  }

  .buttons {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    padding: 6px;
    align-items: center;
    justify-content: center;
  }
}

.w-50 {
  width: 40px;
}

.w-100 {
  width: 100px;

  .bp3-label {
    width: 100px !important;
  }
}

.bp3-dialog {
  .w-100.bp3-label {
    width: 100px;
  }
}

.w-150 {
  width: 150px;
}

.preview-floating-controls {
  position: absolute;
  z-index: 11;
  bottom: 50px;
  width: 260px;
  left: calc(50% - 130px);
  background-color: rgba(0,0,0, 0.35);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 0;
}

.pdf-iframe {
  height: calc(100vh - 100px);
  min-width: 40vw;
  background-color: transparent;
  border: none;
}

.contract-assets-table {
  width: 100%;
  thead {
    tr {
      border-bottom: 1px solid $dark-gray2;
      th {
        font-size: 14px;
        text-transform: uppercase;
        border-bottom: 1px solid $dark-gray2;
        &.ta-r {
          text-align: right;
        }
      }
    }
  }
  tbody {
    tr {
      &:hover {
        td {
          background-color: 1px solid $dark-gray1;
        }
        cursor: pointer;
      }
      td {
        border-bottom: 1px solid $dark-gray2;
        font-size: 14px;
        box-shadow: none !important;
        &.ta-r {
          text-align: right;
        }
      }
    }
  }

}

.bold {
  font-weight: bold;
}

.white {
  color: white;
}

.bp3-menu.shopping-cart-menu {
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20);

  color: white;

  .white {
    color: white;
  }

  .bp3-icon > svg:not([fill]) {
    fill: white;
  }
}

.shopping-cart-item {
  align-content: center;
  display: flex;
  align-items: center;
}

.shopping-cart-icon {
  width: 48px;
  height: 48px;
  margin: 6px 24px 6px 6px;
  background-image: url(./assets/pdf-iicon.png);
  background-size: contain;
  background-repeat: no-repeat;

  background-position: center;
}


.shopping-cart-btn-container {
  position: absolute;
  right: 24px;
  top: 148px;

  .shopping-cart-btn {
    width: 64px;
    height: 64px;
    border: 2px solid white;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $dark-gray2;
    transition: 0.3s;
    box-shadow: 0 3px 36px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;

    .bp3-tag {
      position: absolute;
      top: 0;
      right: 0;
    }
    &:hover {
      background-color: $dark-gray4;
    }
  }
}

.rfip.rfip--default {
  background-color: $dark-gray3;
  margin: 0;
  height: 30px;

  .rfipbtn {
    height: 30px !important;
    min-height: 30px;
  }

  .rfipbtn__icon {
    font-size: 24px;
    color: white;
  }

  .rfipbtn__del {
    color: $dark-gray3;
  }

  .rfipbtn.rfipbtn--default {
    border: none;
    background-color: $dark-gray3 !important;
    height: 30px;
    min-height: 30px;
  }

  .rfipbtn__button {
    background-color: $dark-gray3 !important;
    color: white;
    border: none;
    min-height: 30px;
  }

  .rfipbtn__current {

  }

  .rfipbtn__button--close {
    height: 30px;
  }

  .rfipbtn.rfipbtn--default.rfipbtn--close {
    height: 30px;
  }

  .rfipdropdown.rfipdropdown--default {
    background-color: $dark-gray4;
    border: 1px solid $dark-gray5;

    .rfipbtn {
      height: 30px !important;
    }

    .rfipicons__cp {
      background-color: $dark-gray3 !important;
      color: white;
    }

    .rfipicons__right {
      background-color: $dark-gray3 !important;
      border: 1px solid $dark-gray5;
      color: white;
    }

    .rfipicons__num {
      color: white;
    }

    input, input.rfipsearch__input {
      background-color: $dark-gray3;
      color: white !important;
      padding-left: 6px;
      border-bottom: 1px solid $dark-gray5;
    }

    select {
      color: $light-gray1;
      font-weight: bold;
      text-transform: uppercase;
      border-bottom: 1px solid $dark-gray5;

    }
    .rfipicons__selector  {
      .rfipicons__ibox {
        background-color: $dark-gray3 !important;
        color: white;
        border: 1px solid $dark-gray5;
      }
    }
  }
}

.fas {
  min-width: 16px;
}

.ta-r {
  text-align: right;
}

.separator-bottom {
  padding-bottom: 24px;
  border-bottom: 1px solid $dark-gray4;
  margin-bottom: 24px;
}

.h-50 {
  height: 50px;
}

.bp3-form-group.wide-label {
  .bp3-label {
    width: 100%;
  }
}

.bp3-label {
  font-size: 14px;
  font-weight: bold !important;
  text-transform: uppercase;
  color: $gray4 !important;
}


.align-left {
  label.bp3-label {
    text-align: left !important;
  }
}

.wider-label {
  label.bp3-label {
    width: 192px;
  }
}

// .bp3-dialog.bp3-dark, .bp3-dark .bp3-dialog {}

.bp3-overlay {
  overflow-y: scroll;
}

.tall-menu {
  height: 600px;
  max-height: 600px !important;
  overflow-y: auto;
}

.loader-main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background-image: url(assets/bg-body3.jpg);
  background-size: cover;

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.loader-overlay {
  height: 100%;
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(25px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
}

.asset-form {
  padding: 12px;
  background-color: rgba( #245278, .3 );
  color: white !important;
  .bp3-label span, label.bp3-label span.bp3-text-muted {
    color: white !important;
  }
}

.m-l-40 {
  margin-left: 40px;
}

.bp3-button:not([class*="bp3-intent-"]):disabled, .bp3-button:not([class*="bp3-intent-"]).bp3-disabled {
  color: #182026;
}

.icon-fix .bp3-icon {
  color: gray;
  stroke: gray;
  fill: gray;
}

.bp3-control.bp3-switch input {
  ~ .bp3-control-indicator {
    height: 20px;
    justify-content: center;
    padding-top: 2px;
    font-weight: 500;
    background-color: darken(#54C097, 15%);
  }

  &:hover ~ .bp3-control-indicator {
    background-color: #54C097;
  }
}

.bp3-control.bp3-switch {
  // border: 1px solid black;
}

.bp3-control.bp3-switch input:checked {
  ~ .bp3-control-indicator {
    background-color: #FDB150;
    color: black;
  }

  &:hover ~ .bp3-control-indicator {
    background-color: lighten(#FDB150, 10%);
  }
}

.pointer {
  cursor: pointer;
}

$white: rgba(255, 255, 255, 0.5);

.blob {
  box-shadow: none;
	border-radius: 50%;
  opacity: 0.5;
	transform: scale(1);
	animation: pulse 2s infinite;
}


.m-l-8 {
  margin-left: 8px;
}

.non-bold {
  font-weight: normal;

  label.bp3-label {
    font-weight: normal !important;
  }

  .fake-field {
    font-weight: 500;
    line-height: 28px;
  }
}

label.bp3-label {
  font-weight: normal !important;
}

.backdrop-blur {
  backdrop-filter: blur(24px);
  border: 1px solid rgba(white, 0.1);
  background-color: rgba( #245278, .5 );
}

.no-bg {
  background-color: transparent !important;
}

@keyframes pulse {
	0% {
    opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.p-r-10 {
  padding-right: 10px;
}

table.bp3-html-table.bp3-html-table-condensed th, table.bp3-html-table.bp3-html-table-condensed td, table.bp3-html-table.bp3-small th, table.bp3-html-table.bp3-small td {
  font-weight: normal !important;
}

.bp3-control-group .bp3-button, .bp3-control-group .bp3-html-select, .bp3-control-group .bp3-input, .bp3-control-group .bp3-select {
  color: white;

  &::placeholder {
    color: white;
  }
}

.fs-12 {
  font-size: 14px;
}

.bp3-button {
  .bp3-button-text {
    font-size: normal;
  }
}

.bp3-collapse.m-l-30 li .bp3-menu-item div{
  font-size: 12px;
}

x
.dark-box {
  background-color: rgba($dark-gray2, 0.3);
  padding: 12px;
}

.delivery-form {
  margin-top: 24px;

  .bp3-label {
    width: 120px;
  }

  .bp3-form-group.bp3-inline label.bp3-label {
    text-align: left;
  }

  .bp3-input {
    width: 240px;
    color: white;
  }
}

.products-table {
  .patterns-data-table-item-row {
    min-height: 50px;

    .patterns-data-table-item-cell {
      min-height: 50px;
    }
  }
}

.product-detail {
  .label {
    color: rgba(white, 0.7);
  }
}

.product-dialog {
  width: 1400px;
  .bp3-label {
    color: $dark-gray1;
  }
}

.app-container {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  background: $light-gray5;
  overflow: hidden;
}


.image-box {
  max-width: 100px;
  height: 130px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: static;
  
  .img-container {
    border: 1px solid rgba(255 ,255, 255, 0.3);
    width: 100px;
    height: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      max-width: 100%;
      max-height: 100px;
    }
  }

  .actions {
    justify-self: center;
    align-self: center;
    max-height: 30px;
    opacity: 0;
  }

  margin-right: 12px;

  &:hover {
    .actions {
      opacity: 1;
    }
  }
}

.image-pool-box {
  display: inline; 

  .img-container {
    background: orange;
    border: 1px solid rgba(255 ,255, 255, 0.3);
    width: 100px;
    height: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      background: red;
      max-width: 100%;
      max-height: 100px;
    }
  }
}

.black {
  color: black;
}

.uppercase {
  text-transform: uppercase;
}

.patterns-form textarea {
  width: 100%;
  height: 97px;
}

.p-l-36 {
  padding-left: 36px;
}