.store {
  color: white;

  h1 {
    flex: 1;
    font-size: 48px;
    font-weight: 200;
    padding-top: 12px;
    margin-top: 0;
    margin-bottom: 24px;
    padding-left: 24px;
  }

  .bp3-tag {
    color: white;
    border: 1px solid $light-gray3;
  }

  .bp3-button.bp3-outlined:hover {
    color: white;
  }
  
  .store-category-tag {
    margin-right: 12px;
    color: rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.7);
    &.active {
      color: white;
    }

    &.bp3-intent-success {
      color: white;
      border: 1px solid white;

      &:hover {
        color: white;
        border-color: white;
      }
    }
  }

  .ecommerce-products {
    flex: 3;
    height: calc(100vh - 180px);
    overflow-y: auto;
  }
  
}