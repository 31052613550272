.progress-chart {
  background-color: rgba( #245278, .7 );
  border-radius: 7px;
  padding-bottom: 12px;
  color: white;  

  h4 {
    padding: 12px;
    // background-color: rgba(darken(#245278, 5%), 0.7);
    color: white;
    margin-top: 0;
    border-radius: 7px;
    font-weight: 500;
  }
}