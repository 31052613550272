.hero-box {
  padding: 0px 24px;
  height: 136px;
  border-radius: 8px;
  background-color: rgba( #245278, .3 );
  backdrop-filter: blur(24px);
  display: flex;
  flex-direction: row;

  .image {
    flex: 1;
    margin-top: 4px;
    img {
      height: 128px;
    }
  }

  .number {
    margin-top: 48px;
    font-size: 36px;
    font-weight: bold;
    text-align: right;
  }

  .label {
    font-weight: bold;
    text-transform: uppercase;
    text-align: right;
    color: white;
  }
}