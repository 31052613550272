
.patterns-data-table {
  backdrop-filter: blur(24px);
  padding: 24px;
  // background-color: rgba( darken(#245278, 10%), .5 );
  background-color: $dark-gray4;
  display: block;
  flex: unset;
  flex-direction: unset;
  width: 100%;
  font-weight: 500;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  .patterns-toolbar  {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 32px;
  }

  .patterns-data-table-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    // background-color: rgba( #245278, .7 );
    background-color: $dark-gray3;
    color: white;
    border-color: $dark-gray2;
    font-size: 14px;
    height: 32px;
  }

  .patterns-data-table-content {
    overflow-y: auto;
    flex: unset;
    flex-shrink: unset;
  }

  .patterns-data-table-item-row {
    // background-color: rgba( #245278, .5 );
    border-bottom: 1px solid $dark-gray2;
//
    &:hover {
      background-color: $blue1;
    }
  }

  .patterns-data-table-item-row:nth-child(even) {
    background-color: transparent;
    &:hover {
      background-color: $blue1;
    }
    // background-color: rgba( #245278, .7 );
  }

  .patterns-data-table-cell {
    border-left: 1px solid $dark-gray2;
    background-color: transparent;
    color: white;
    font-size: 14px;
    line-height: 32px;
    padding-left: 24px;
    padding-right: 24px;
    .bp3-button {
      font-size: 14px;
    }

    strong {
      font-weight: 500 !important;
    }

    .bp3-button-text {
      font-weight: 500 !important;
    }

    &.header {
      background-color: transparent;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      font-weight: 500;
      &:hover {
        background-color: darken( #245278, 3%);
      }
    }
  }

  .bp3-icon {
    color: rgba(white, 0.7);
  }
  .bp3-button {
    .bp3-button-text {
      color: white;
    }
  }
  .bp3-tag, .bp3-tag.bp3-minimal {
    color: white;
  }

  .bp3-skeleton {
    animation: 1000ms linear infinite alternate skeleton-glower;
    // background-color: rgba( #245278, .7 ) !important;
    background-color: $dark-gray2;
    background-clip: padding-box !important;
    border: none;
    // border-color: rgba( #245278, .9 );
    border-color: $dark-gray4;
    border-radius: 2px;
    box-shadow: none !important;
    color: transparent !important;
    cursor: default;
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none;
    opacity: 0.01;
  }
}

.patterns-datatable-autoheight {
  height: 100%;
  overflow: hidden;
  position: relative;

  .patterns-data-table {
    height: 100%;
  }
}
